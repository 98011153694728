import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const BrianDent = () => {
  return (
    <Layout>
      <Seo title="Doctor Brian Dent, DVM, DACVS" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br />
                Dr. Brian
                <br />
                Dent
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/dr-brian-dent.jpeg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Brian Dent is a native Ohioan and a recent arrival to
                Colorado. A first-generation college student, he completed his
                pre-veterinary studies at the University of Findlay in
                Northwestern Ohio. He went on to receive his DVM degree from The
                Ohio State University in 2016. Thereafter, he completed a
                rotating internship at North Carolina State University where he
                was the intern of the year, prior to returning to his Buckeye
                roots. Dr. Dent completed a three-year surgical residency and
                combined Master’s of Comparative Veterinary Science at The Ohio
                State University in 2020. Following his residency, Dr. Dent
                spent a year working at multi-specialty hospital in the Los
                Angeles area.
              </p>
              <p>
                Dr. Dent is driven by a passion for helping our four-legged
                friends and their loving families. Few things make him happier
                than seeing a successful recovery and giving pets a second
                chance at their normal, rambunctious life.{" "}
              </p>
              <p>
                Dr. Dent is an outdoor enthusiast and never turns down an
                opportunity for adventure. He is an avid rock climber and has
                climbed all over the US and internationally, and enjoys a wide
                array of other activities, from hiking and backpacking to white
                water rafting, kayaking, skiing, and even skydiving. He is
                thrilled to be a part of the team at Colorado Canine Orthopedics
                and to call Colorado his home.
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/889237081"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default BrianDent
